<template>
    <b-navbar toggleable="lg" type="dark" :style="'background-color:' + background +';'">
        <!--<b-navbar-toggle ></b-navbar-toggle>-->

        <b-collapse is-nav>
            <b-container fluid>
                <b-navbar-nav>
                    <router-link class="d-flex align-items-center mx-md-1 mx-0 navigation-link" :style="style" :to="{name: 'inicio'}" >
                        <span class="material-icons-outlined navbar-add-icon mr-1">
                            home
                        </span>
                        <div >
                            <span class="text-left navbar-add-titles d-block">
                                Inicio
                            </span>
                        </div>
                    </router-link>
                    <b-nav-item-dropdown no-caret>
                        <!-- Using 'button-content' slot -->
                        <template #button-content>
                            <div class="d-flex align-items-center mx-md-1 mx-0 navigation-link" :style="style">
                                <span class="material-icons-outlined navbar-add-icon mr-1">
                                    list_alt
                                </span>
                                <div >
                                    <span class="text-left navbar-add-titles d-block">
                                        Categorías
                                    </span>
                                </div>
                            </div>
                        </template>
                        <b-row style="min-width: 70rem">
                            <b-col cols="4" v-for="(category, index) of categoriesHoyostool" :key="index">
                                <b-dropdown-item href="#"  style="width: 100%;" @click="toItemsCategory(category)">
                                    {{category.name}} 
                                 </b-dropdown-item>
                            </b-col>
                        </b-row>
                    </b-nav-item-dropdown>
                    <router-link class="d-flex align-items-center mx-md-1 mx-0 navigation-link" :style="style" :to="{name: 'ListadoPedidosClienteWeb'}" >
                        <span class="material-icons-outlined navbar-add-icon mr-1">
                            receipt_long
                        </span>
                        <div >
                            <span class="text-left navbar-add-titles d-block">
                                Pedidos
                            </span>
                        </div>
                    </router-link>
                    <!--<b-nav-item href="#"  v-if="this.categoriesEmpresa.length > 0"><b>Otras Categorías</b></b-nav-item>-->
                    <div href="#" class="d-flex align-items-center mx-md-3 mx-0 navigation-link" :style="style" @click="logout">
                        <span class="material-icons-outlined navbar-add-icon mr-1">
                            power_settings_new
                        </span>
                        <div >
                            <span class="text-left navbar-add-titles d-block">
                                Salir
                            </span>
                        </div>
                    </div>
                </b-navbar-nav>

            </b-container>
        </b-collapse>
    </b-navbar>
</template>

<script>
import { mapState, mapActions} from 'vuex'
export default {
    computed: {
        ...mapState('configuration', ['background', 'cartItemCount', 'categoriesEmpresa', 'categoriesHoyostool', 'configuracionClienteWeb']),
        style() {
            return {
                'color': this.configuracionClienteWeb.menu_color,
            }
        },
        menuItemsColor() {
            return ('#000000')
        }
    },
    methods: {
        ...mapActions('auth', ['logout']),
        toCart(){
            this.$router.push({ name: 'processCartClienteWeb' });
        },
        toItemsCategory(category){
            this.$router.push({ name: 'listadoInventario', params:{ categoryCode: category.category_code, slug: category.slug, categoria: category.name}});
        }
    }
}
</script>

<style scoped>

.navigation-link {
    cursor: pointer;
    text-decoration: none;
    font-weight: 700;
    font-size: 15px;
}
</style>
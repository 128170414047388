<template>
<div>
    <div class="py-2">
        <b-navbar toggleable="lg" class="menu-navb">
            <b-navbar-brand class="navbar-logo" href="#" :to="{name: 'inicio'}">
                <b-img :src="empresaLogo" alt="Logo"></b-img>
            </b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
                <b-nav-form>
                    <vue-typeahead-bootstrap
                            class="buscador-input"
                            v-model="searchText"
                            :data="item_search_autocomplete"
                            :serializer="serializarValor"
                            @hit="searchItem"
                            @keyup.enter.prevent="searchItem"
                            placeholder="Buscar productos"
                            :maxMatches="30"
                            @input="instantSearch"
                            />
                    <b-button variant="info" @click.prevent="searchItem">Buscar</b-button>
                </b-nav-form>

                

                <router-link class="d-flex align-items-center mx-md-3 mx-0 navbar-left-section navbar-section-pointer" :to="{name: 'perfil'}" >
                    <span class="material-icons-outlined navbar-add-icon mr-2">
                        account_circle
                    </span>
                    <div >
                        <span class="text-left navbar-add-titles d-block">
                            MI CUENTA
                        </span>
                        <span>
                            Detalle
                        </span>
                    </div>
                </router-link>
                <div class="d-flex align-items-center navbar-left-section navbar-section-pointer" :disabled="cartItemCount < 1" @click.prevent="toCart()">
                    <span class="material-icons-outlined navbar-add-icon mr-2">
                        shopping_cart
                    </span>
                    <div>
                        <span class="text-left navbar-add-titles d-block">
                            CARRITO
                        </span>
                        <b-badge variant="danger" pill>{{cartItemCount}}</b-badge>
                        <span>
                            Items
                        </span>
                    </div>
                </div>
            </b-navbar-nav>
            </b-collapse>
        </b-navbar>
        <b-navbar toggleable class="menu-movile">
            <b-navbar-brand class="navbar-logo" href="#" :to="{name: 'inicio'}">
                <b-img :src="empresaLogo" alt="Fluid image"></b-img>
            </b-navbar-brand>
            <router-link class="d-flex ml-auto align-items-center navbar-left-section navbar-section-pointer" :to="{name: 'perfil'}" >
                <span class="material-icons-outlined navbar-add-icon mr-2">
                    account_circle
                </span>
            </router-link>
            <div class="d-flex align-items-center navbar-left-section navbar-section-pointer mr-2" :disabled="cartItemCount < 1" @click.prevent="toCart()">
                <span class="material-icons-outlined navbar-add-icon mr-0">
                    shopping_cart
                </span>
                <div>
                    <b-badge variant="danger" pill>{{cartItemCount}}</b-badge>
                </div>
            </div>
            
            <b-navbar-toggle target="navbar-toggle-collapse">
                <template #default="{ expanded }">
                    <b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
                    <b-icon v-else icon="chevron-bar-down"></b-icon>
                </template>
            </b-navbar-toggle>

            <b-collapse id="navbar-toggle-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
                <b-input-group class="py-3">
                    <vue-typeahead-bootstrap
                    class="buscador-input"
                    v-model="searchText"
                    :data="item_search_autocomplete"
                    :serializer="serializarValor"
                    @hit="searchItem"
                    @keyup.enter.prevent="searchItem"
                    placeholder="Buscar productos"
                    :maxMatches="30"
                    @input="instantSearch"
                    />
                    <b-input-group-append>
                        <b-button variant="info" @click.prevent="searchItem">Buscar</b-button>
                    </b-input-group-append>
                </b-input-group>
                <b-nav-item href="#" :to="{name: 'inicio'}" ><b>Inicio</b></b-nav-item>
                <b-nav-item v-b-toggle.sidebar-1> <b> Categorías</b></b-nav-item>
                <b-nav-item href="#" :to="{name: 'ListadoPedidosClienteWeb'}" ><b>Pedidos</b></b-nav-item>
                <b-nav-item href="#" @click="logout"><b>Salir</b></b-nav-item>
                
            </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
    <MenuNavbarItems class="menu-navb" />
    
    <b-sidebar id="sidebar-1" backdrop shadow>
      <div class="px-3 py-2">
        <b-list-group-item :to="{name: 'listadoInventario', params:{ categoryCode: category.category_code, slug: category.slug, categoria: category.name}}"
                    v-for="(category, index) of categoriesHoyostool" :key="index"
            class="d-flex justify-content-between align-items-center text-white">
            <div class="text-dark">
                {{category.name}}
            </div>
        </b-list-group-item>
      </div>
    </b-sidebar>
    <template>
        <b-modal
            v-model="dialog"
            title="Ingrese sucursal a la canasta"
            no-close-on-backdrop
            no-close-on-esc
            hide-header-close
            >
            <b-form-select v-model="saveSucursal" :options="sucursales"
            text-field="nombre"
            value-field="id"
            >
            <template v-slot:first>
                <b-form-select-option value="" disabled>-- Por favor seleccione una Sucursal --</b-form-select-option>
            </template>
            
            </b-form-select>
            <template v-slot:modal-footer>
                <div class="w-100">
                <b-button variant="success" 
                :disabled="saveSucursal === ''"
                @click="storeSucursalInUserCart" block>
                    Guardar
                </b-button>
                </div>
            </template>
        </b-modal>
        </template>
    </div>
</template>

<script>
import {debounce} from 'lodash';
import { mapState, mapMutations, mapActions } from 'vuex'
import MenuNavbarItems from './components/MenuNavbarItems.vue'
export default {
     computed: {
        ...mapState('configuration', ['background','empresaLogo', 'cartItemCount', 'sucursalSelected', 'sucursales', 'typeSearch', 'categoriesHoyostool', 'configuracionClienteWeb']),
        ...mapState('auth', ['user']),
    },
    data(){
        return {
            clickeable: false,
            saveSucursal: '',
            searchText: '',
            dialog: false,
            typeSearchSelected: 1,
            item_search_autocomplete: []
        }
    },
    created() {
        this.getUserCart();
    },
    components:{
        MenuNavbarItems
    },
    methods: {
        ...mapMutations(['setLoading']),
        ...mapActions('auth', ['logout']),
        ...mapActions('configuration', {
          _getSucursalSelected: 'getSucursalSelected',
          _setTypeSearchSelected: 'setTypeSearchSelected'}
          ),
        searchItem(){
            this.$router.push({ 
                name: 'SearchItems', 
                params:{
                    texto: this.searchText, 
                    time: new Date().getTime(),
                    } 
                });
        },
        async getUserCart(){
            this.setLoading(true)
            await this.$conectar.post('/cart/get-user-cart')
            .then(response=>{
                if (response.data.error == true) { 
                    this.dialog= true;
                }
            })
            .catch(error => {
                console.log(error);
            })
            this.setLoading(false)
        },
        async storeSucursalInUserCart(){
            this.setLoading(true)
            let sucursalSelected = { id: this.saveSucursal}
            await this.$conectar.post('/cart/store-sucursal-user-cart', sucursalSelected)
            .then(response =>{
                if(response.data.error == false){
                    this._getSucursalSelected();
                    this.dialog= false;
                }
            })
            .catch(error => {
                console.log(error);
            })
            this.setLoading(false)
        },
        updateSearchSelected(){
            this._setTypeSearchSelected(this.typeSearchSelected);
        },
        toCart(){
            if(this.cartItemCount > 0){
                if(this.user.tipo_terceros == 1){
                    this.$router.push({ name: 'processCartVendedor' });
                }else{
                    this.$router.push({ name: 'processCartClienteWeb' });
                }
            }
        },
        instantSearch: debounce(function() {
            this.item_search_autocomplete = [];
            let searchObject = { 
                search: this.searchText
            }
                
            this.$conectar.post('busqueda/instant-search-items', searchObject)
            .then(response =>{
                if(response.data.error == false){
                    this.item_search_autocomplete = response.data.searchResults;
                }
            })
            .catch(e => {
                console.log(e.response);
            })
            
        }, 500),
        
        serializarValor(item) {
			return item.nombre;
		},
        onArticuloSeleccionado(item) {
            this.instantSearch(item.nombre)
		}
    }
}
</script>

<style  scoped>
.navbar-logo img{
    height: 130px;
    width: 250px;
    object-fit: fill;
    border-radius: 5px;
}
.buscador-input{ 
  width: 350px;
}


.navbar-section-pointer{
    cursor: pointer;
    text-decoration: none;
    color: black;
}

.navbar-add-icon{
    color: #f04706  !important;
    font-size: 40px;
    transition: transform 0.3s ease;
}

.navbar-add-titles{
    color: #313c46;
    font-weight: 600;
    font-size: 16px;
}

.navbar-left-section:hover .navbar-add-icon  {
  transform: scaleX(-1);
}




@media all and (max-width:991px) {
    .menu-navb{
        display: none;
    }

    .navbar-add-icon{
        font-size: 30px;
    }
    
} 
@media all and (min-width:992px) {
    .menu-movile{
        display: none;
    }
    
} 


@media all and (max-width:1024px) {
    
    .buscador-input{ 
        width: 220px;
    }
}

</style>
<template>

    <!-- Footer -->
    <footer class="text-lg-start text-white footer-section" >
        <!-- Section: Social media -->

        <!-- Section: Links  -->
        <section>
        <div class="container-fluid text-md-start mt-5">
            <!-- Grid row -->
            <div class="row mt-3">
            <!-- Grid column -->
            <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                <!-- Content -->
                <span class="section-titles">Información de contacto</span>
                
                <hr>
                <div class="text-section">
                    <div class="d-flex align-items-center navbar-left-section pb-4">
                        <span class="material-icons-outlined support-add-icon mr-4">
                            support_agent
                        </span>
                        <div>
                            <span class="text-left text-white d-block">
                                Necesitas ayuda? llamanos
                            </span>
                            <span v-if="configuracionClienteWeb.telefono" class="telemovil">
                                <a :href="'tel:'+configuracionClienteWeb.telefono">{{configuracionClienteWeb.telefono}}</a>
                            </span>
                        </div>
                    </div>
                    <p><b-icon icon="house-door-fill" class="mr-2"></b-icon> {{configuracionClienteWeb.direccion}}</p>
                    <p><b-icon icon="envelope" class="mr-2"></b-icon> {{configuracionClienteWeb.email_contacto}}</p>
                </div>
            </div>
            
            <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4 text-section">
                <!-- Links -->
                <span class="section-titles ">Mi cuenta</span>
                <hr />
                <p>
                    <router-link :to="{name: 'perfil'}">Perfil</router-link>
                </p>
                <p>
                    <router-link :to="{name: 'ListadoPedidosClienteWeb'}">Pedidos</router-link>
                </p>
            </div>
            <!-- Grid column -->

            <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4 text-section">
                <!-- Links -->
                <span class="section-titles">Horario</span>
                <hr />
                <p>{{configuracionClienteWeb.horarios}}</p>

            </div>

            <!-- Grid column -->
            <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <!-- Links -->
                <span class="section-titles">{{ sucursalSelected }}</span>
                <hr />
                <i class="bi bi-facebook"></i>
                <b-img :src="empresaLogo" fluid alt="Fluid image"></b-img>

            </div>
            
            <!-- Grid column -->
            </div>
            <!-- Grid row -->
        </div>
        </section>
    </footer>
    <!-- Footer -->

</template>
<script>
import { mapState} from 'vuex'
export default {
    computed: {
        ...mapState('configuration', ['background','empresaLogo', 'sucursalSelected', 'configuracionClienteWeb']),
    },
}
</script>

<style scoped>
img{
    height: 130px;
    width: 250px;
    object-fit: fill;
    border-radius: 5px;
 
}
.footer-section{
    background-color: #313c46;
}

.section-titles{
    font-size: 18px;
    font-weight: bold;
    color: #fff;
}

.text-section p,
.text-section a {
    color: #ccc;
    transition: color 0.5s ease;
    text-decoration: none;
}

.text-section p:hover,
.text-section a:hover {
    color: #f04706;
}

.support-add-icon{
    color: #f04706 !important;
    font-size: 40px;
    transition: transform 0.3s ease;
}
.telemovil a{
    color: #f04706 !important;
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
}

@media print {
    .footer-section {
      display: none;
    }
  }
</style>
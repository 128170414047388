<template>
    <div class="wrapper bg-white">
        
        <b-container>
            <!-- Page Content  -->
            <div id="content" class="d-flex flex-column min-vh-100">
                <navBar />
                <div class="flex-grow-1">
                    <router-view/>
                </div>
                <Footer />
            </div>
        </b-container>
    </div>
</template>

<script>

import navBar from './Navbar'
import { mapState } from 'vuex'
import Footer from './components/Footer.vue'
export default {
    computed: {
        ...mapState('configuration', ['sucursalSelected']),
        ...mapState('auth', ['user']),
    },
    components:{
        navBar,
        Footer
    }
}
</script>

<style scope>
a{
    color: black;
}
a:hover{
    cursor: pointer;
}

.theme--light.v-application {
    background: #e6e6e6;
}
.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}

#sidebar {
    min-width: 300px;
    max-width: 300px;
    transition: all 0.3s;
}

#sidebar.active {
    margin-left: -300px;
}

#sidebar .sidebar-header {
    padding: 20px;  
}
#content {
    width: 100%;
    min-height: 100vh;
    transition: all 0.3s;
    
}

body { 
    padding-right: 0 !important;
}
/* ---------------------------------------------------s
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
    #sidebar {
        margin-left: -300px;
    }
    #sidebar.active {
        margin-left: 0;
    }
    #sidebarCollapse span {
        display: none;
    }
    #content {
        transition: all 0.3s;
        margin-right: -300px;
    }
}

@media print{
	#sidebar {
        margin-left: -300px;
    }
    .btn {
        display: none;
    }
}

</style>